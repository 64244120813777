<!--
 * @Author: Axs <i@axs.email>
 * @Date: 2021-11-10 16:34:36
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-11-22 17:42:53
 * @Description: 卡包
 * @FilePath: /src/views/kfc/card/index.vue
-->
<template>
    <div class="page card">
        <div class="tab-list">
            <div class="tab-item" :class="{ select: tabIndex == 0 }" @click="switchTab(0)">
                优惠券
            </div>
            <div class="tab-item" :class="{ select: tabIndex == 1 }" @click="switchTab(1)">
                代金券
            </div>
        </div>
        <div class="search">
            <div class="search-box">
                <input
                    type="text"
                    class="input"
                    v-model="code"
                    placeholder="请输入兑换码"
                    @keyup.enter="exchange"
                />
                <div class="btn" @click="exchange(code)">兑换</div>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <div class="almost-expired" v-if="item.almostExpired">即将过期</div>
                <div class="card-box">
                    <img v-if="tabIndex == 0" :src="item.productImgUrl" class="product-img" />
                    <div class="voucher" v-else-if="tabIndex == 1">
                        <div class="price">{{ item.reduceAmount }}</div>
                        <div class="tip">支付立减</div>
                    </div>
                    <div class="card-info">
                        <div class="card-name">{{ item.cardName }}</div>
                        <div class="card-tags" v-if="item.cardTags">
                            <div class="tag-item" v-if="!item.takeout">
                                堂食、外带、美团配送可用
                            </div>
                            <div class="tag-item" v-else>宅急送可用</div>
                            <div class="tag-item" v-for="v in item.cardTags" :key="v">{{ v }}</div>
                        </div>
                        <div class="card-title">{{ item.cardTitle }}</div>
                        <div class="use-count" v-if="tabIndex == 0">
                            剩余
                            <span class="span">{{ item.availableCount }}</span>
                            次
                        </div>
                        <div class="card-time">{{ item | timeFilter }}</div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="exchange-tip">
                    <div class="tip" @click="showTip(item)">兑换说明</div>
                    <div
                        class="btn cancel"
                        @click="cancelUseCard(item)"
                        v-if="1 == tabIndex && item.selected"
                    >
                        取消使用
                    </div>
                    <div class="btn" @click="useCard(item)" v-else>立即使用</div>
                </div>
            </div>
            <div class="padding-bottom iphone-x-adaptation" v-if="0 == type"></div>
        </div>
        <div
            class="dialog-select-product"
            v-if="selectProductStatus"
            @click.stop="selectProductStatus = false"
        >
            <div class="content" @click.stop="">
                <div class="close" @click.stop="selectProductStatus = false"></div>
                <div class="product-image">
                    <img
                        class="image"
                        :src="
                            selectProductList[selectProductIndex] &&
                            selectProductList[selectProductIndex].productImgUrl
                        "
                        alt=""
                        srcset=""
                    />
                </div>
                <div class="title-name">
                    {{
                        selectProductList[selectProductIndex] &&
                        selectProductList[selectProductIndex].productName
                    }}
                </div>
                <div class="product-list">
                    <div
                        class="max-name max-style"
                        :class="{ 'max-color': index == selectProductIndex }"
                        v-for="(item, index) in selectProductList"
                        :key="index"
                        @click="switchSelectProductIndex(index)"
                    >
                        {{ item.productName }}
                    </div>
                </div>
                <div class="quantity-line">
                    <div class="price">
                        {{
                            selectProductList[selectProductIndex] &&
                            selectProductList[selectProductIndex].productOriginPrice
                        }}
                    </div>
                    <div class="choose-name">
                        {{
                            selectProductList[selectProductIndex] &&
                            selectProductList[selectProductIndex].productName
                        }}
                    </div>
                </div>
                <div
                    class="btn-add-cart"
                    :class="{ 'no-add': !selectProductList[selectProductIndex].available }"
                    @click="submitSelectProduct()"
                >
                    {{ selectProductList[selectProductIndex].available ? '立即兑换' : '已售罄' }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { PAGE_RESTAURANT_SELECT,PAGE_ADDRESS_LIST } from 'page';
import {
    GET_USER_AVAILABLE_CARD_LIST,
    EXCHANGE_CARD,
    USE_KFC_CARD,
    GET_KFC_CARD_PRODUCT,
    USE_CARD_TO_PRODUCT,
    GET_KFC_VOUCHER,
    GET_KFC_VOUCHER_4_CART,
    USE_KFC_VOUCHER,
} from 'api';
export default {
    data() {
        return {
            // 0 首页我的卡包
            // 1 原 菜单中我的卡包
            // 2 优惠助手卡包
            type: 0,
            code: '',
            submitCode: '',
            waitExchangeCode: '',
            list: [],
            storeCode: '',
            selectProductStatus: false,
            selectProductList: [],
            selectProductIndex: -1,
            tabIndex: 0,
            waitUseCardList: [],
            overUseCardList: [],
        };
    },
    props: {
        componentType: {
            type: Number,
            default: 0,
        },
        setTabIndex: {
            type: Number,
            default: 0,
        },
        setWaitUseCardList: {
            type: Array,
            default: null,
        },
        takeout: {
            type: Boolean,
            default: null,
        },
        addressId: {
            type: Number,
            default: 0,
        },
        setStoreCode: {
            type: String,
            default: '',
        },
    },
    filters: {
        timeFilter(item) {
            if (item.availableBeginTime && item.availableEndTime) {
                return (
                    moment(item.availableBeginTime).format('YYYY.MM.DD') +
                    ' - ' +
                    moment(item.availableEndTime).format('YYYY.MM.DD')
                );
            } else if (item.availableBeginTime && !item.availableEndTime) {
                return moment(item.availableBeginTime).format('YYYY.MM.DD') + '起';
            } else if (item.availableBeginTime && !item.availableEndTime) {
                return moment(item.availableEndTime).format('YYYY.MM.DD') + '止';
            } else {
                return '长期有效';
            }
        },
    },
    created() {
        this.tabIndex = this.setTabIndex;
        this.waitUseCardList = this.setWaitUseCardList || [];
        this.overUseCardList = [];
        this.type = this.$route.query.type || this.componentType;
        this.storeCode = this.setStoreCode || this.$route.query.storeCode;
        this.waitExchangeCode = this.$route.query.code || '';
        this.getList();
    },
    methods: {
        getList() {
            let requestUrl = '';
            let requestData = {
                storeCode: this.storeCode,
                takeout: this.takeout,
            };
            if (this.type == 0) {
                requestUrl = this.tabIndex == 0 ? GET_USER_AVAILABLE_CARD_LIST : GET_KFC_VOUCHER;
            } else {
                requestUrl =
                    this.tabIndex == 0 ? GET_USER_AVAILABLE_CARD_LIST : GET_KFC_VOUCHER_4_CART;
            }
            if (this.takeout) {
                requestData.addressId = +this.addressId;
            }
            this.$util.get(requestUrl, requestData).then(res => {
                if (res.success) {
                    this.list = res.data;
                    this.useWaitExchangeCode();
                    this.useWaitCard();
                }
            });
        },
        useWaitExchangeCode() {
            if (!this.waitExchangeCode) return;
            let isHasCoupon = false;
            for (let i = 0; i < this.list.length; i++) {
                const item = this.list[i];
                console.log(item.code, this.waitExchangeCode);
                if (item.code == this.waitExchangeCode) {
                    isHasCoupon = true;
                    break;
                }
            }
            if (isHasCoupon) {
                // this.$toast(`该优惠券您已兑换了`);
            } else {
                this.exchange(this.waitExchangeCode);
            }
        },
        switchTab(index) {
            this.tabIndex = index;
            this.getList();
        },
        // 兑换
        exchange(code = '') {
            code = code || this.code;
            if (code.length == 0) {
                return this.$toast('请输入有效的兑换码');
            }
            this.$util
                .post(EXCHANGE_CARD, {
                    code: code,
                })
                .then(res => {
                    if (res.success) {
                        this.code = '';
                        this.waitExchangeCode = '';
                        this.$toast('兑换成功');
                        this.getList();
                    }
                });
        },
        // 兑换说明
        showTip(item) {
            this.$confirm.open({
                title: '兑换说明',
                message: `<div style="text-align:left;">${item.instruction.replaceAll(
                    '\n',
                    '<br>'
                )}</div>`,
                hideCancelBtn: true,
                confirmText: '我知道了',
            });
        },
        switchSelectProductIndex(index) {
            this.selectProductIndex = index;
        },
        // 立即使用
        async useCard(item) {
            if (0 == this.type) {
                if (0 == this.tabIndex) {
                    this.$store.commit('setWaitUseCardList', [item.code]);
                }
                let pageUrl = "";
                let pageData = {};
                if(item.takeout){
                    pageUrl = PAGE_ADDRESS_LIST;
                    pageData.type = 1;
                    return this.$util.pageJump(pageUrl,pageData);
                }
                else{
                    return this.$confirm.open({
                        title: '温馨提示',
                        message: '该优惠券可在“堂食/外带”和“美团配送”中使用，请选择就餐方式？',
                        confirmText: '堂食/外带',
                        cancelText:'<span style="color:#000000;">美团配送</span>',
                        success: () => {
                            pageUrl = PAGE_RESTAURANT_SELECT;
                            this.$util.pageJump(pageUrl,pageData);
                        },
                        error:()=>{
                            pageUrl = PAGE_ADDRESS_LIST;
                            pageData.type = 3;
                            return this.$util.pageJump(pageUrl,pageData);
                        }
                    });
                }

                
            }
            this.submitCode = item.code;

            if (this.tabIndex == 1) {
                let kfcVoucherRequestData = {
                    codes: [this.submitCode],
                    storeCode: this.storeCode,
                };
                if (this.takeout) {
                    kfcVoucherRequestData.addressId = +this.addressId;
                }
                const kfcVoucherRes = await this.$util.post(USE_KFC_VOUCHER, kfcVoucherRequestData);
                if (kfcVoucherRes.success) {
                    if (1 == this.type) {
                        this.$toast(`${item.cardName}使用成功`);
                        this.$emit('callback', {
                            success: true,
                            message: `${item.cardName}使用成功`,
                        });
                        this.getList();
                    } else if (2 == this.type) {
                        this.$toast(`${item.cardName}使用成功`);
                        this.$emit('callback', {
                            success: true,
                            message: `${item.cardName}使用成功`,
                        });
                        this.getList();
                    }
                }
                return;
            }

            let kfcCardProductRequestData = {
                code: this.submitCode,
                storeCode: this.storeCode,
                takeout: this.takeout,
            };
            if (this.takeout) {
                kfcCardProductRequestData.addressId = +this.addressId;
            }
            const kfcCardProductRes = await this.$util.post(
                GET_KFC_CARD_PRODUCT,
                kfcCardProductRequestData
            );
            if (kfcCardProductRes.success && kfcCardProductRes.data.optionProducts.length) {
                let tempSelectProductIndex = -1;
                for (let i = 0; i < kfcCardProductRes.data.optionProducts.length; i++) {
                    const item = kfcCardProductRes.data.optionProducts[i];
                    if (item.available) {
                        tempSelectProductIndex = i;
                        break;
                    }
                }
                this.selectProductIndex = tempSelectProductIndex == -1 ? 0 : tempSelectProductIndex;
                this.selectProductList = kfcCardProductRes.data.optionProducts;
                if (kfcCardProductRes.data.optionProducts.length == 1) {
                    this.submitSelectProduct();
                } else {
                    this.selectProductStatus = true;
                }
            }
        },
        async useWaitCard() {
            if (this.waitUseCardList.length == 0) return;

            for (let i = 0; i < this.waitUseCardList.length; i++) {
                const waitUseCardItem = this.waitUseCardList[i];
                if (this.overUseCardList.indexOf(waitUseCardItem) != -1) {
                    continue;
                } else {
                    this.overUseCardList.push(waitUseCardItem);
                }
                for (let j = 0; j < this.list.length; j++) {
                    const item = this.list[j];
                    if (item.code == waitUseCardItem) {
                        await this.useCard(item);
                        break;
                    }
                }
            }
        },
        // 取消使用
        async cancelUseCard(item) {
            const kfcVoucherRes = await this.$util.post(USE_KFC_VOUCHER, {
                codes: [],
                storeCode: this.storeCode,
            });
            if (kfcVoucherRes.success) {
                if (1 == this.type) {
                    this.$toast(`${item.cardName}取消成功`);
                    this.$emit('callback', {
                        success: true,
                        message: `${item.cardName}取消成功`,
                    });
                    this.getList();
                } else if (2 == this.type) {
                    this.$toast(`${item.cardName}取消成功`);
                    this.$emit('callback', {
                        success: true,
                        message: `${item.cardName}取消成功`,
                    });
                    this.getList();
                }
            }
        },
        submitSelectProduct() {
            let item = this.selectProductList[this.selectProductIndex];
            console.log(item.productName);
            if (!item.available) {
                return this.$toast('该商品已售罄');
            }
            let requestData = {
                code: this.submitCode,
                storeCode: this.storeCode,
                productId: item.productId,
            };

            if (this.takeout) {
                requestData.addressId = +this.addressId;
            }
            this.$util.post(USE_CARD_TO_PRODUCT, requestData).then(res => {
                if (res.success) {
                    this.selectProductStatus = false;
                    if (1 == this.type) {
                        this.$toast(`${item.productName}兑换成功`);
                        this.$emit('callback', {
                            success: true,
                            message: `${item.productName}兑换成功`,
                        });
                        this.getList();
                    } else if (2 == this.type) {
                        this.$toast(`${item.productName}兑换成功`);
                        this.$emit('callback', {
                            success: true,
                            message: `${item.productName}兑换成功`,
                        });
                        this.getList();
                    }
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
@import url('~@/lib/base.less');
.card {
    .tab-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: .pxToRem(80) [];
        line-height: .pxToRem(80) [];
        background-color: white;
        border-radius: 0 0 .pxToRem(20) [] .pxToRem(20) [];
        .tab-item {
            flex: 1;
            text-align: center;
            font-size: .pxToRem(28) [];
            color: rgba(0, 0, 0, 0.9);
            &.select {
                color: @theme_color_first;
                font-weight: bold;
            }
        }
    }
    .search {
        padding: .pxToRem(16) [] .pxToRem(30) [];
        .search-box {
            display: flex;
            width: 100%;
            height: .pxToRem(66) [];
            align-items: center;
            background: rgba(0, 0, 0, 0.05);
            border-radius: .pxToRem(32) [];
            .input {
                flex: 1;
                line-height: .pxToRem(40) [];
                background: rgba(0, 0, 0, 0);
                text-indent: .pxToRem(40) [];
                font-size: .pxToRem(28) [];
            }
            .btn {
                width: .pxToRem(140) [];
                line-height: .pxToRem(40) [];
                text-align: center;
                font-size: .pxToRem(30) [];
                color: rgba(0, 0, 0, 0.9);
            }
        }
    }
    .list {
        padding: .pxToRem(1) [] .pxToRem(20) [];
        .item {
            width: .pxToRem(710) [];
            margin: 0 auto;
            margin-bottom: .pxToRem(20) [];
            background: white;
            border-radius: .pxToRem(40) [];
            box-shadow: .pxToRem(3) [] .pxToRem(3) [] .pxToRem(8) [] 0 rgba(0, 0, 0, 0.1);
            overflow: hidden;
            .almost-expired {
                position: absolute;
                top: .pxToRem(50) [];
                left: .pxToRem(-40) [];
                transform: rotate(-45deg);
                width: .pxToRem(200) [];
                font-size: .pxToRem(20) [];
                color: #ffffff;
                line-height: .pxToRem(34) [];
                text-align: center;
                background: @theme_color_first;
                z-index: 1;
            }
            .card-box {
                font-size: 0;
                display: flex;
                .product-img {
                    width: .pxToRem(165) [];
                    height: .pxToRem(120) [];
                    margin-top: .pxToRem(64) [];
                    margin-left: .pxToRem(40) [];
                }
                .voucher {
                    width: .pxToRem(160) [];
                    height: .pxToRem(160) [];
                    background: rgba(252, 219, 40, 0.1);
                    border: .pxToRem(1) [] dashed #f8c32f;
                    box-sizing: border-box;
                    border-radius: .pxToRem(30) [];
                    margin: .pxToRem(40) [] .pxToRem(4) [] .pxToRem(0) [] .pxToRem(30) [];
                    .price {
                        position: absolute;
                        top: .pxToRem(24) [];
                        width: 100%;
                        text-align: center;
                        line-height: .pxToRem(80) [];
                        font-size: .pxToRem(80) [];
                        font-weight: bold;
                        color: @theme_color_first;
                        &::before {
                            content: '￥';
                            line-height: .pxToRem(40) [];
                            font-size: .pxToRem(36) [];
                            font-weight: bold;
                            color: @theme_color_first;
                            margin-right: .pxToRem(-10) [];
                        }
                    }
                    .tip {
                        position: absolute;
                        bottom: .pxToRem(24) [];
                        width: 100%;
                        text-align: center;
                        line-height: .pxToRem(38) [];
                        font-size: .pxToRem(26) [];
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.5);
                    }
                }
                .card-info {
                    flex: 1;
                    margin-top: .pxToRem(30) [];
                    margin-bottom: .pxToRem(8) [];
                    margin-left: .pxToRem(16) [];
                    margin-right: .pxToRem(40) [];
                    .card-name {
                        line-height: .pxToRem(40) [];
                        font-size: .pxToRem(30) [];
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.9);
                    }
                    .card-tags {
                        margin-top: .pxToRem(8) [];
                        .tag-item {
                            display: inline-block;
                            border: .pxToRem(1) [] solid @theme_color_first;
                            font-size: .pxToRem(20) [];
                            color: @theme_color_first;
                            line-height: .pxToRem(28) [];
                            padding-left: .pxToRem(16) [];
                            padding-right: .pxToRem(16) [];
                            margin-right: .pxToRem(8) [];
                            border-radius: .pxToRem(8) [];
                            margin-top: .pxToRem(8) [];
                        }
                    }
                    .card-title {
                        margin-top: .pxToRem(16) [];
                        line-height: .pxToRem(56) [];
                        font-size: .pxToRem(40) [];
                        font-weight: bold;
                        color: @theme_color_first;
                    }
                    .use-count {
                        line-height: .pxToRem(36) [];
                        font-size: .pxToRem(24) [];
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.5);
                        .span {
                            color: @theme_color_first;
                        }
                    }
                    .card-time {
                        line-height: .pxToRem(36) [];
                        font-size: .pxToRem(24) [];
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
            .line {
                width: .pxToRem(710) [];
                height: .pxToRem(40) [];
                background: url('~@/assets/icons/icon-card-line.png');
                background-size: cover;
            }
            .exchange-tip {
                display: flex;
                justify-content: space-between;
                padding: 0 .pxToRem(40) [];
                .tip {
                    line-height: .pxToRem(60) [];
                    font-size: .pxToRem(24) [];
                    color: rgba(0, 0, 0, 0.5);
                    padding-left: .pxToRem(40) [];
                    margin-top: .pxToRem(6) [];
                    margin-bottom: .pxToRem(24) [];
                    &::after {
                        content: ' ';
                        .base-center-y;
                        left: 0;
                        width: .pxToRem(24) [];
                        height: .pxToRem(24) [];
                        background: url('~@/assets/icons/icon-question-mark.png');
                        background-size: cover;
                    }
                }
                .btn {
                    width: .pxToRem(164) [];
                    height: .pxToRem(60) [];
                    line-height: .pxToRem(60) [];
                    margin-top: .pxToRem(6) [];
                    text-align: center;
                    font-size: .pxToRem(24) [];
                    color: #ffffff;
                    background: @theme_color_first;
                    border-radius: .pxToRem(30) [];
                    &.cancel {
                        background: rgba(0, 0, 0, 0.3);
                    }
                }
            }
        }
        .padding-bottom {
            height: @tabbarHeight;
        }
    }
    .dialog-select-product {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        .content {
            .base-center;
            width: .pxToRem(650) [];
            background: white;
            border-radius: .pxToRem(40) [];
            overflow: hidden;
            .close {
                position: absolute;
                top: .pxToRem(30) [];
                right: .pxToRem(30) [];
                background: url('~@/assets/icons/icon-login-close.png');
                background-size: cover;
                .sq(32);
                z-index: 10;
            }

            .product-image {
                width: .pxToRem(650) [];
                height: .pxToRem(390) [];
                .image {
                    .base-center-x;
                    bottom: 0;
                    max-height: .pxToRem(360) [];
                    max-width: .pxToRem(495) [];
                }
                &:after {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: .pxToRem(1) [];
                    background: rgba(0, 0, 0, 0.15);
                }
            }

            .title-name {
                margin-top: .pxToRem(30) [];
                font-size: .pxToRem(34) [];
                font-weight: 600;
                color: rgba(0, 0, 0, 0.9);
                padding: 0 .pxToRem(32) [];
                text-align: left;
            }
            .product-list {
                padding: 0 .pxToRem(32) [];
                margin-top: .pxToRem(20) [];
                margin-bottom: .pxToRem(24) [];
                max-height: calc(100vh - .pxToRem(1118) []);
                verflow: auto;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                text-align: left;
                .max-name {
                    display: inline-block;
                    height: .pxToRem(60) [];
                    line-height: .pxToRem(60) [];
                    border-radius: .pxToRem(8) [];
                    font-size: .pxToRem(26) [];
                    padding: 0 .pxToRem(20) [];
                    margin-right: .pxToRem(12) [];
                    margin-bottom: .pxToRem(12) [];
                    box-sizing: border-box;
                }
                .max-style {
                    background-color: #ffffff;
                    color: rgba(0, 0, 0, 0.5);
                    border: .pxToRem(1) [] solid rgba(0, 0, 0, 0.5);
                    &.max-color {
                        background: #fdf0f0;
                        color: @theme_color_first;
                        border: .pxToRem(1) [] solid @theme_color_first;
                    }
                }
            }

            .quantity-line {
                width: 100%;
                height: .pxToRem(120) [];
                background: #f2f2f2;
                .price {
                    position: absolute;
                    top: .pxToRem(17) [];
                    left: .pxToRem(40) [];
                    color: @theme_color_first;
                    height: .pxToRem(48) [];
                    line-height: .pxToRem(48) [];
                    font-size: .pxToRem(36) [];
                    padding-left: .pxToRem(24) [];
                    font-weight: 600;
                    &:before {
                        content: '¥';
                        position: absolute;
                        bottom: .pxToRem(2) [];
                        left: 0;
                        font-weight: 400;
                        font-size: .pxToRem(24) [];
                        height: .pxToRem(36) [];
                        line-height: .pxToRem(36) [];
                        width: .pxToRem(24) [];
                        text-align: center;
                    }
                }
                .choose-name {
                    position: absolute;
                    bottom: .pxToRem(20) [];
                    left: .pxToRem(40) [];
                    text-align: left;
                    color: rgba(0, 0, 0, 0.5);
                    font-size: .pxToRem(24) [];
                    height: .pxToRem(36) [];
                    line-height: .pxToRem(36) [];
                    width: .pxToRem(400) [];
                    .overflow(1);
                }
                .num-box {
                    .base-center-y;
                    right: .pxToRem(40) [];
                    height: .pxToRem(56) [];
                    .btn-plus {
                        position: absolute;
                        top: 0;
                        right: 0;
                        .sq(56);
                        z-index: 1;
                    }
                    .btn-reduce {
                        position: absolute;
                        top: 0;
                        left: 0;
                        .sq(56);
                        z-index: 1;
                    }
                    .quantity {
                        height: .pxToRem(56) [];
                        line-height: .pxToRem(56) [];
                        font-size: .pxToRem(26) [];
                        color: rgba(0, 0, 0, 0.9);
                        padding: 0 .pxToRem(74) [];
                    }
                }
            }
            .btn-add-cart {
                height: .pxToRem(90) [];
                line-height: .pxToRem(90) [];
                width: 100%;
                text-align: center;
                background: @theme_color_first;
                color: white;
                font-size: .pxToRem(28) [];
                &.no-add {
                    background: rgba(0, 0, 0, 0.5);
                }
            }
        }
    }
}
</style>
